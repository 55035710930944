<template>
    <button
        :class="{ wide: wide, disabled: disabled }"
        :disabled="disabled"
        class="basic-button"
        @click.stop.prevent="clickHandler()"
    >
        <span v-if="disabled && disabledtooltip" :v-tooltip="disabledtooltip"></span>
        <svgicon
            v-if="icon"
            :class="{ notalone: !!text, mobilehide: !mobileshowicon }"
            :color="icon.color"
            :height="icon.height"
            :icon="icon.name"
            :width="icon.width"
        />
        <span v-if="text">{{ text }}</span>
    </button>
</template>
<script>
export default {
    props: {
        href: { type: String, default: null },
        icon: { type: Object, default: null },
        text: { type: String, default: null },
        wide: { type: String, default: null },
        disabled: { type: Boolean, default: null },
        action: { type: String, default: null },
        mobileshowicon: { type: Boolean, default: true },
        disabledtooltip: { type: String, default: null },
    },
    computed: {
        noFollowCheck() {
            const nf = this.href && this.href.toLowerCase().indexOf('desjardins') > -1;
            return nf ? 'nofollow' : '';
        },
    },
    methods: {
        clickHandler() {
            if (this.href & !this.disabled) {
                window.location.href = this.href;
            }
        },
    },
};
</script>

<style lang="scss">
.basic-button {
    white-space: nowrap;
    display: flex;
    font-size: 18px;
    color: #fff;
    padding: 13px;
    text-decoration: none;
    align-items: center;
    border: none;
    font-weight: 900;
    background-color: $green;
    text-align: center;

    &:hover {
        color: #fff;
        background-color: $dark-green;
    }

    &.disabled {
        color: #1e2828;
        background-color: $light-green !important;

        &:hover {
            color: #1e2828;
            background-color: $light-green !important;
        }
    }

    svg {
        &.notalone {
            margin-right: 7px;
        }
        &.mobilehide {
            @include media('<md') {
                display: none;
            }
        }
    }
}
.wide {
    width: 100%;
}
</style>
